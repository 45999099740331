import 'babel-polyfill';
import { signIn } from './libs/auth.js';

const form = document.getElementById('login-form');
const usernameField = document.getElementById('username');
const passwordField = document.getElementById('password');
const submitButton = document.getElementById('submit-button');
const errorValue = document.getElementById('errorValue');
const formErrors = document.getElementById('formErrors');
const loadingWrapper = document.getElementById('loadingWrapper');

const signUser = async () => {
  const email = usernameField.value.trim();
  const password = passwordField.value.trim();
  submitButton.disabled = true;
  formErrors.style.visibility = 'hidden';

  if (!email && !password) {
    errorValue.innerHTML = 'Please enter email and password.';
    formErrors.style.visibility = 'visible';
    submitButton.disabled = false;
    loadingWrapper.classList.add('display-none');
    return;
  }

  submitButton.disabled = true;
  loadingWrapper.classList.remove('display-none');
  const loginResponse = await signIn(email, password);
  if (!loginResponse || !!loginResponse.error) {
    errorValue.innerHTML = 'Email or password are incorrect. Please try again.';
    formErrors.style.visibility = 'visible';
    submitButton.disabled = false;
  } else {
    loadingWrapper.classList.add('hidden');
    sessionStorage.setItem('userEmail', email);
    window.location.href = '/client.html';
  }
};

if (form) {
  form.addEventListener('submit', async event => {
    event.preventDefault();

    signUser();
  });

  usernameField.addEventListener('input', () => {
    if (usernameField.value) {
      formErrors.style.visibility = 'hidden';
    }
  });

  passwordField.addEventListener('input', () => {
    if (passwordField.value) {
      formErrors.style.visibility = 'hidden';
    }
  });

  usernameField.addEventListener('keydown', event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      signUser();
    }
  });

  passwordField.addEventListener('keydown', event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      signUser();
    }
  });
}
