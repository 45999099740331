import { defaultConfig } from '../config/config';

export const signIn = async (email, password) => {
  if (!email || !password) {
    return null;
  }

  try {
    const response = await fetch(`${defaultConfig.serverUrl}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
      // credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error(errorData.error);
      return errorData;
    } else {
      const loginResponse = await response.json();
      document.cookie = `token=${loginResponse.token};path=/`;
      sessionStorage.setItem('logged', true);
      return loginResponse.token;
    }
  } catch (error) {
    console.log('Error during sign in', error);
  }
};

export const logOut = async () => {
  try {
    sessionStorage.removeItem('logged');
    document.cookie = 'token=; Max-Age=0; path=/';
    window.location.href = '/';
  } catch (error) {
    console.log('Error during sign in');
  }
};

export const verifyUserToken = async () => {
  try {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
    const response = await fetch(`${defaultConfig.serverUrl}/verify-token`, {
      method: 'POST',
      headers
      // credentials: 'include'
    });

    const parsedResponse = await response.json();
    if (parsedResponse.token) {
      // document.cookie = `token=${refreshToken};path=/`; put when revaliated token
      return true;
    } else {
      console.error('token veryfication response is invalid', parsedResponse.error);
      return null;
    }
  } catch (error) {
    console.log('Error during getting user token veryfication', error);
  }
};
